@import '../../../theme/theme';

// modal container for black opacity
.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

// white modal
.modal {
  background-color: $backgroundColor;
  border: 0px;
  border-radius: 8px;
  max-height: 80vh;
  height: 100%;
  width: 80vw;
  max-width: 1200px;
  overflow: hidden;
  outline: none;
}

.currencyGrid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 10px;
  padding: 20px 10px;
}

// adds scroll property to radio button container
.radioButtonsContainer {
  overflow-y: scroll;
  height: calc(100% - 54px);
  padding: 20px;
  transition: all 0.3s ease;
}

// changes the width of scrollbar
.radioButtonsContainer::-webkit-scrollbar {
  width: 16px;
}

// styles the scrollbar thumb to rounded corners
.radioButtonsContainer:hover::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid $backgroundColor;
  background-color: $backgroundColor;
}

// shows thumb when mouse is over the radio buttons container
.radioButtonsContainer:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

// styles the section heading to bold and dark color
.sectionHead {
  font-family: $primaryFontFamily;
  font-size: 14px;
  font-weight: bold;
  color: $primaryFontColor;
  margin: 0px 0px 0px 20px;
}

// button container for CTA
.buttonContainer {
  border-top: 1px solid $dividerColor;
  padding: 6px 0px;
  display: flex;
  justify-content: flex-end;
}

// styles the button text to bold and green
.buttonContainer p {
  color: $primaryColor;
  font-weight: bold;
  font-size: 14px;
  margin: 0px;
  z-index: 1;
}

// properties to prepares the button for hover animation
.button {
  padding: 10px 14px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

// default property of div to apply transition on
.button div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
}

// changes the color, dimension and position of div for transition effect
.button:hover div {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $dividerColor;
  border-radius: 4px;
  transition: all 0.3s ease;
}

// adds right margin to right most button
.button:last-of-type {
  margin-right: 20px;
}

// If active state of button is required
// ************************************
// .button:active {
//   background-color: $primaryColor;
// }

// .button:active div {
//   top: 50%;
//   left: 50%;
//   width: 0px;
//   height: 0px;
// }

// .button:active p {
//   color: #ffffff;
// }

@media only screen and (max-width: 991px) {
  .currencyGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .currencyGrid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    padding: 20px 10px;
  }
}
